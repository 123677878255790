import {Kategorie, KategorieName, KategorieTyp} from "../common/Veranstaltung";
import {Fragment} from "preact";
import {getKategorieLinks} from "../common/settings";

export function getKategorieTexte(name: KategorieName): string {
    switch(name) {
        case "Ausbildung":
            return "zertifizierten Ausbildungen"
        case "Seminar":
            return "unseren Seminaren"
        case "Vortrag":
            return "unseren Vorträgen"
        case "Outdoor":
            return "Veranstaltungen, die wir im Freien durchführen"
        case "Blockseminar":
            return "Seminaren, die in kompakten Blöcken stattfinden"
        case "Wochenkurs":
            return "Kursen im Umfang von einer Woche"
        case "IN":
            return "Kursen, die durch den IN zertifiziert werden können"
        case "ICI":
            return "Kursen, die durch den ICI zertifiziert werden können"
        case "DVNLP":
            return "Kursen, die durch den DVNLP zertifiziert werden können"
        case "SocietyOfNlp":
            return "Kurse, die durch die Society of NLP zertifiziert werden können"
        case "Nlpaed":
            return "Kurse, die durch den nlpaed zertifiziert werden können"
        case "ProC":
            return "Kurse, die durch den ProC zertifiziert werden können"
        case "NlpBasic":
            return "unseren Nlp-Basic-Kursen"
        case "NlpPractitioner":
            return "unseren Nlp-Practitioner-Kursen"
        case "NlpMaster":
            return "unseren Nlp-Master-Kursen"
        case "NlpAdvMaster":
            return "unseren Nlp-Advanced-Master-Kursen"
        case "Trainer":
            return "unseren Nlp-Trainer-Kursen"
        case "LernCoach":
            return "unseren LernCoach-Kursen nach nlpaed"
        case "LernCoachMaster":
            return "unseren LernCoach-Master-Kursen nach nlpaed"
        case "HypnoCoach":
            return "unseren HypnoCoach-Kursen"
        case "SystemischerCoach":
            return "dem Systemisch-Integrativen-Coach nach ProC"
        case "Mediator":
            return "unseren Mediator*in-Kursen"
        case "NurOnline":
            return "Veranstaltungen, die komplett online stattfinden"
        case "NurPräsenz":
            return "Veranstaltungen, die komplett in Präsenz stattfinden"
        case "Hybrid":
            return "Veranstaltungen, die sowohl Online- als auch Präsenzteilnahme erlauben"
        case "BlendedLearning":
            return "Veranstaltungen mit Online- und Präsenzinhalten"
        case "Abends":
            return "Veranstaltungen, die abends stattfinden"
        case "Vormittags":
            return "Veranstaltungen, die vormittags stattfinden"
        case "Werktags":
            return "Veranstaltungen, die Werktags stattfinden"
        case "Wochenende":
            return "Veranstaltungen, die am Wochenende stattfinden"
        case "AbendsUndWochenende":
            return "Veranstaltungen, die abends und am Wochenende stattfinden"
        case "VormittagsUndWochenende":
            return "Veranstaltungen, die vormittags und am Wochenende stattfinden"
        case "Gratis":
            return "Veranstaltungen, an denen Sie gratis teilnehmen können"
    }
}

export function WeitereInfos({kategorien}: { kategorien: Kategorie[] }) {
    const relevanteKategorien = kategorien.filter(kat =>
        kat.typ === KategorieTyp.Einstellbar || kat.typ === KategorieTyp.Automatisch)

    return (
        <section>
            <h3>Weitere Infos</h3>
            <p>Unter den folgenden Links finden sie mehr Informationen:</p>
            <ul>
                {relevanteKategorien.map(kat =>
                    <Fragment>
                        <li><a href={getKategorieLinks(kat.name as KategorieName)}>Zu {getKategorieTexte(kat.name as KategorieName)}</a></li>
                    </Fragment>)}
            </ul>
        </section>
    );
}