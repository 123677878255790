import {Guid, KategorieName} from "./Veranstaltung";

export function getKategorieLinks(name: KategorieName) : string {
    switch (name) {
        case "Ausbildung":
            return "/ausbildungen/"
        case "Seminar":
            return "/seminare/themenseminare"
        case "Vortrag":
            return "/seminare/vortraege/"
        case "Outdoor":
            return "/kategorien/outdoor/"
        case "Blockseminar":
            return "/kategorien/blockseminar/"
        case "Wochenkurs":
            return "/kategorien/wochenkurs/"
        case "IN":
            return "/kategorien/in/"
        case "ICI":
            return "/kategorien/ici/"
        case "DVNLP":
            return "/kategorien/dvnlp/"
        case "SocietyOfNlp":
            return "/kategorien/society-of-nlp/"
        case "Nlpaed":
            return "/kategorien/nlpaed/"
        case "ProC":
            return "/kategorien/proc/"
        case "NlpBasic":
            return "/ausbildungen/nlp-basic/"
        case "NlpPractitioner":
            return "/ausbildungen/nlp-practitioner/"
        case "NlpMaster":
            return "/ausbildungen/nlp-master/"
        case "NlpAdvMaster":
            return "/ausbildungen/adv-nlp-master/"
        case "Trainer":
            return "/ausbildungen/trainer/"
        case "LernCoach":
            return "/ausbildungen/lerncoach/"
        case "LernCoachMaster":
            return "/ausbildungen/lerncoach-master/"
        case "HypnoCoach":
            return "/ausbildungen/hypnocoach/"
        case "SystemischerCoach":
            return "/ausbildungen/systemisch-integrativer-coach/"
        case "Mediator":
            return "/ausbildungen/mediator/"
        case "NurOnline":
            return "/kategorien/nur-online/"
        case "NurPräsenz":
            return "/kategorien/nur-praesenz/"
        case "Hybrid":
            return "/kategorien/hybrid/"
        case "BlendedLearning":
            return "/kategorien/blended-learning/"
        case "Abends":
            return "/kategorien/abends/"
        case "Vormittags":
            return "/kategorien/vormittags/"
        case "Werktags":
            return "/kategorien/werktags/"
        case "Wochenende":
            return "/kategorien/wochenende/"
        case "AbendsUndWochenende":
            return "/kategorien/abends-und-wochenende/"
        case "VormittagsUndWochenende":
            return "/kategorien/vormittags-und-wochenende/"
        case "Gratis":
            return "/kategorien/gratis/"
    }
}

export const apiServerDomain = "https://katalog.nlp-impulse.com";

export function veranstaltungsAuzügeUrl(apiServer: string = apiServerDomain) {
    return `${apiServer}/WebsiteApi/veranstaltungen/auszug`;
}

export function veranstaltungsDatenUrl(id: string, apiServer: string = apiServerDomain) {
    return `${apiServer}/WebsiteApi/veranstaltungen/${id}/daten`;
}

export function aktiveTrainerApiUrl(apiServer: string = apiServerDomain) {
    return `${apiServer}/WebsiteApi/trainer/aktive`;
}

export function trainerKomplettApiUrl(id: string, apiServer: string = apiServerDomain) {
    return `${apiServer}/WebsiteApi/trainer/${id}/komplett`
}

export function anmeldeApiUrl(apiServer: string = apiServerDomain){
    return `${apiServer}/WebsiteApi/anmeldung`;
}

export const trainerKopfPlatzhalterUrl = "/trainerkoepfe/platzhalter.png";
export const trainerKopfPlatzhalterGroßUrl = "/trainerkoepfe/platzhalter_large.png";

export function trainerKopfUrl(id: Guid) {
    return `/trainerkoepfe/${id}.jpg`;
}

export function trainerKopfGroßUrl(id: Guid) {
    return `/trainerkoepfe/${id}_large.jpg`;
}

export function trainerDetailsUrl(id: Guid) {
    return `/team/#trainer_${id}`;
}

export function veranstaltungsDetailUrl(id: Guid) {
    return `/veranstaltungsdetails/?id=${id}`;
}

export const kontaktUrl = '/kontakt/'